.signup {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: whitesmoke;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #c4c4c4;
    border-radius: 7px;
    padding: 30px;
    width: 400px;

    -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.13);

    @media (max-width: 650px) {
      width: 65vw;
    }

    .header {
      color: #000;
      font-family: "RubikBold";
      margin-bottom: 20px;
      text-align: center;

      h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (max-width: 650px) {
        width: 100%;
      }

      .input-field {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        label {
          margin-bottom: 5px;
          color: #000;
          font-family: "RubikRegular";
          font-size: var(--primary-fz);
        }

        input {
          padding: 10px;
          outline: none;
          border-radius: 7px;
          border: 1px solid #b4b4b4;
          background-color: #f0f0f080;
          color: #000;
          font-size: var(--primary-fz);
        }
      }

      .loading-indicator {
        font-weight: 700;
        animation: overlay 0.5s ease infinite;
      }

      @keyframes overlay {
        to {
          opacity: 0.8;
        }
      }

      button {
        height: 50px;
        font-size: var(--secondary-fz);
        background-color: var(--button-bg);
        font-weight: bold;
        color: #fff;
        border: none;
        border-radius: 6px;
        transition-duration: 0.3s;
        cursor: pointer;
        margin-bottom: 10px;
        font-family: "RubikBold";
      }

      button:hover {
        background-color: var(--button-hover-bg);
        color: #ececec;
      }
    }

    p {
      color: #000;
      font-family: "RubikRegular";

      .link {
        color: #000;
        margin-left: 5px;
      }
    }
  }
}
